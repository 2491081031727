<template>
    <div id="news">
        <div class="mbx wrap">
            当前位置：首页 - 文章列表
        </div>
        <div class="news_content wrap clearfix">
            <div class="news_left">
                <div class="news_cat">
                    <ul>
                        <li @click="toUrl(1)" :class="searchData.type==1?'red':''">系统公告</li>
                        <li @click="toUrl(2)" :class="searchData.type==2?'red':''">新闻资讯</li>
                        <li @click="toUrl(3)" :class="searchData.type==3?'red':''">行业资讯</li>
                        <li @click="toUrl(4)" :class="searchData.type==4?'red':''">常见问题</li>
                        <li @click="toUrl(5)" :class="searchData.type==5?'red':''">关于我们</li>
                    </ul>
                </div>
                <div class="system_list">
                    <div class="title">系统公告</div>
                    <ul>
                        <li @click="type = item.type" v-for="item in articleList">
                            <span></span>
                            <router-link :to="'/news/'+item.id+'?type='+item.type">{{item.title}}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="news_right">
                <div v-if="showList">
                    <div class="news_item" v-for="item in list">
                        <div class="title">
                            <router-link :to="'/news/'+item.id">{{item.title}}</router-link>
                        </div>
                        <div class="content">{{item.summary}}</div>
                        <div class="views clearfix">
                            <span>{{$moment(item.createTime).format('Y-MM-DD HH:mm')}}</span>
                            <div class="btn" @click="$router.push('/news/'+item.id)">查看详情</div>
                        </div>
                    </div>
                    <div class="home_fy_block">
                        <el-pagination v-if="total>0" background @current-change="handleCurrentChange" :current-page="searchData.pageNo" :page-size="searchData.pageSize" 
                        layout="total, prev, pager, next, jumper" :total="total">
                        </el-pagination>
                    </div>
                </div>
                <router-view v-else></router-view>
            </div>
        </div>
        <div class="xian"></div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            currentPage3: 1,
            showList: true,

            articleList:[],
            searchData:{
                pageNo:1,
                pageSize:10,
                type:1,
            },
            list:[],
            total:0,
            loading:false,//防重复点击
        }
    },
    created(){
        if(this.$route.name == 'News'){
            this.showList = true
        }else{
            this.showList = false
        }
        this.getArticleList();
        var type = this.$route.query.type;
        if(type){
            this.searchData.type = type;
        }
        this.getList();
    },
    mounted(){},
    watch: {
        $route(to,from){
            var type = this.$route.query.type;
            if(type){
                this.searchData.type = type;
            }
            if(to.name == 'News'){
                this.showList = true;
                this.getList();
            }else{
                this.showList = false;
            }
        }
    },
    methods: {
        handleCurrentChange(){},
        handleSizeChange(){},
        toUrl(type){
            this.searchData.type = type
            this.searchData.pageNo = 1;
            var path = this.$route.path;
            if(path!='/news'){
                this.$router.push('/news?type='+this.searchData.type);
            }else{
                this.getList();
            }
        },
        //获取最新公告列表
        getArticleList(){
            this.$request.post({
                url:'/ReportApi/business/newlists',
                params:{
                    pageNo:1,
                    pageSize:6,
                    type:1,
                },
                success: res => {
                    this.articleList = res[0];
                }
            })
        },
        //文章列表
        getList(){
            this.loading = true;
            this.$request.post({
                url:'/ReportApi/business/newlists',
                params:this.searchData,
                success: res => {
                    this.list = res[0];
                    this.total = res[1];
                },
                finally: () => {
                    this.loading = false;
                }
            })
        },
        handleCurrentChange(val){
            this.searchData.pageNo = val;
            this.getList();
        },
    }
}
</script>

<style lang="scss" scoped>
#news{
    .mbx{
        line-height: 60px;
        font-size: 14px;
        color: #666;
    }
    .news_content{
        .news_left{
            float: left;
            width: 260px;
            .news_cat{
                border-radius: 6px;
                border: 1px solid #dadada;
                ul{
                    li{
                        line-height: 55px;
                        text-align: center;
                        border-bottom: 1px solid #dadada;
                        font-size: 18px;
                        cursor: pointer;
                    }
                    .red{
                        color: #fff;
                        background: #f80;
                        border-radius: 6px 6px 0 0;
                    }
                }
            }
            .system_list{
                margin-top: 40px;
                border: 1px solid #dadada;
                .title{
                    line-height: 55px;
                    background-color: #F2F2F2;
                    color: #f80;
                    text-align: center;
                    font-size: 18px;
                }
                ul{
                    margin-top: 8px;
                    li{
                        line-height: 35px;
                        font-size: 14px;
                        padding: 0 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        span{
                            width: 5px;
                            height: 5px;
                            display: block;
                            border-radius: 50%;
                            margin-right: 8px;
                            background: #f80;
                            margin-top: 14px;
                            float: left;
                        }
                    }
                }
            }
        }
        .news_right{
            float: right;
            width: 860px;
            .news_item{
                padding: 20px;
                border: 1px solid #dadada;
                border-radius: 6px;
                margin-bottom: 20px;
                .title{
                    font-weight: bold;
                    line-height: 35px;
                    margin-bottom: 15px;
                    a{
                        color: #f80;
                    }
                }
                .content{
                    max-height: 75px;
                    color: #666;
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    line-height: 25px;
                }
                .views{
                    margin-top: 20px;
                }
                span{
                    float: right;
                    font-size: 14px;
                    color: #999;
                    line-height: 32px;
                }
                .btn{
                    line-height: 32px;
                    height: 32px;
                    width: 110px;
                    text-align: center;
                    border-radius: 6px;
                    background: #f80;
                    color: #fff;
                    float: right;
                    margin-right: 20px;
                    cursor: pointer;
                }
            }
        }
    }
    .xian{
        height: 5px;
        background: #eee;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .home_fy_block{
        margin-top: 30px;
        text-align: right;
    }
}
</style>